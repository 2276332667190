import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loader: any;
  constructor(private loadingBar:LoadingBarService) { 

    this.loader = this.loadingBar.useRef();
  }

  startLoading() {
    this.loader.start();
  }

  stopLoading() {
    this.loader.complete();
  }
}
