export const environment = {
    production: true,
    clientHost: 'https://staging.staftr.com/',
    apiUrl: 'https://staging.staftr.com/api/',
    usSpecificTimezoneOnly: true,
    tokenRefreshBefore: -2,
    dateFormat: 'MM/dd/yyyy',
    title: "Staftr",
    firebaseConfig :{
        apiKey: "AIzaSyAr3j3zU9oZ7OYp6ITBBgnwKUoHrEVcm7M",
        authDomain: "mplace-dd342.firebaseapp.com",
        databaseURL: "https://mplace-stafter.firebaseio.com",
        projectId: "mplace-dd342",
        storageBucket: "",
        messagingSenderId: "136536185534",
        logging: true
    },
    unitTestConfig: {
        defaultPassword: "Tester@1234"
    },
};
